.App {
  display: flex;
}

.App-sidebar {
  width: 200px;
  height: 100vh;
  position: fixed;
  /*background-color: #282c34;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  z-index: 1;
}

.App-title {
  /*color: #F5F5F5; !* Off-white color *!*/
  margin-bottom: 1rem; /* Adjust as needed */
}

.App-nav a {
  color: #61dafb;
  margin: 10px 0;
  text-decoration: none;
}

.App-nav {
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-content {
  height: calc(100vh - 100px);
  margin-left: 200px; /* Adjust this if your sidebar width is different */
  padding: 20px;
}


/* You can add more styling here */
